/* score */
.score-bar-container {
  width: 357px;
  height: 45px;
  display: flex;
  align-self: center;
  flex-flow: column;
}

.signaler {
  width: 27px;
  height: 27px;
  opacity: 50%;
  position: relative;
  left: 0px;
}

.score-bar {
  width: 357px;
  min-height: 25px;

  background: linear-gradient(270deg, var(--primary) 0%, var(--secondary) 100%);
}

.score-bar-values {
  width: 357px;

  display: flex;
  flex-direction: row;
  flex-flow: row;
  justify-content: space-between;
}

.score-bar-min {
  line-height: 29px;

  font-family: 'Roboto-Bold';
  font-size: 1.02rem;
  color: var(--secondary);
}

.score-bar-max {
  line-height: 29px;

  font-family: 'Roboto-Bold';
  font-size: 1.02rem;
  color: var(--primary);
}

.score-plan {
  min-width: 80vw;
  max-width: 99vw;

  padding: var(--gap);

  background-color: var(--primary);
  border-radius: var(--radius);

  display: grid;
  place-items: center;
  text-align: center;
  grid-gap: var(--gap);
}

/* mini-score */
.mini-score-bar-container {
  width: 100px;
  height: 20px;
  display: flex;
  align-self: center;
  flex-flow: column;
}

.mini-signaler {
  width: 8px;
  height: 8px;
  opacity: 50%;
  position: relative;
  left: 0px;
}

.mini-score-bar {
  width: 100px;
  min-height: 15px;

  display: flex;
  justify-content: center;

  background: linear-gradient(270deg, var(--primary) 0%, var(--secondary) 100%);

  font-size: 1.01rem;
  color: var(--tertiary);
}

.mini-score-bar-values {
  width: 100px;

  display: flex;
  flex-direction: row;
  flex-flow: row;
  justify-content: space-between;
}

.mini-score-bar-min {
  line-height: 9px;

  font-family: 'Roboto-Bold';
  font-size: 1rem;
  color: var(--secondary);
}

.mini-score-bar-max {
  line-height: 9px;

  font-family: 'Roboto-Bold';
  font-size: 1rem;
  color: var(--primary);
}

.big-box {
  width: 100%;
  height: 140px;
}

.secondary-text {
  color: var(--secondary);
}

.ultra-big-text {
  font-size: 6rem;
  font-family: 'Recoleta SemiBold';
  text-align: center;
}

@media (min-width: 500px) {
  /* desktop version */

  .big-box {
    width: 335px;
    height: 231px;
  }
}
