.item-cards {
  width: 98vw;
  height: auto;

  padding: var(--gap);

  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  place-items: center;
}

.item-card {
  width: 300px;
  min-height: 150px;
  height: auto;

  margin: var(--gap);
  padding: var(--gap);

  border-radius: var(--radius);

  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  place-items: center;
}

.card-color-even {
  background-color: var(--secondary);
}

.card-color-odd {
  background-color: var(--primary);
}

.item-card-id {
  width: 100%;
  font-size: 1.01rem;
  color: var(--tertiary);
}

.item-card-questionnaire {
  width: 100%;
  font-size: 1.02rem;
}

.item-card-with-image {
  width: 300px;
  min-height: 250px;

  margin: var(--gap);
  padding: var(--gap);

  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  place-items: center;
}

.item-card-image {
  background-color: var(--pure-white);
  width: 100%;
  height: 100px;

  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  place-items: center;

  /* border-bottom: solid 2px var(--primary); */
}

.item-card-image > img {
  clear: both;

  width: 200px;
  height: 100px;

  margin: 0px;
  padding: 0px;
}

.item-card-description {
  width: 100%;
}

.card-description-color-even,
.card-description-color-odd {
  color: var(--tertiary);
}

.item-card-link {
  width: 100%;
}

.item-card-button {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  place-items: flex-end;
}

.item-card-button > button {
  display: block;

  min-width: 80px;
  height: 3vh;

  font-size: 1.01rem;
}

.card-button-color-odd {
  background-color: var(--secondary);
  color: var(--tertiary);
}

.card-button-color-odd:hover {
  background-color: var(--primary);
  color: var(--tertiary);
}

.card {
  height: auto;
}

.card-image {
  width: var(--box-width);
}

.card-image > img {
  width: var(--box-width);
}

.card-details {
  width: var(--box-width);
  height: 180px;
  padding: 5px;

  display: grid;
  place-items: start;
  grid-gap: var(--gap);

  background-color: var(--primary);

  text-align: justify;
  color: var(--tertiary);

  border: solid 2px var(--secondary);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 11px;
}

.card-title {
  justify-self: center;
  text-align: center;
}

.long-text {
  width: 80%;
  padding: var(--gap);

  justify-self: center;
  text-align: center;
}

@media (min-width: 500px) {
  /* desktop version */

  .home-links {
    display: flex;
    flex-direction: row;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: flex-end;
    place-items: flex-end;
    align-self: end;
    justify-self: end;
    width: 400px;
  }
}
