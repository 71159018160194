.header-product {
  width: 100vw;
  display: grid;
  place-items: center;
  grid-gap: var(--gap);
}

.header-product > img {
  clear: both;
  margin: 0px;
  padding: 0px;
}

.user-input,
.pwd-input {
  padding: var(--gap);

  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 0px center;

  text-indent: 40px;
  font-family: 'Roboto-Black';
}

/* placeholder */
.user-input::-webkit-input-placeholder,
.pwd-input::-webkit-input-placeholder {
  color: var(--tertiary);
  opacity: 0.9;
}
.user-input::-moz-placeholder,
.pwd-input::-moz-placeholder {
  color: var(--tertiary);
  opacity: 0.9;
}
.user-input:-ms-input-placeholder,
.pwd-input:-ms-input-placeholder {
  color: var(--tertiary);
  opacity: 0.9;
}
.user-input::-ms-input-placeholder,
.pwd-input::-ms-input-placeholder {
  color: var(--tertiary);
  opacity: 0.9;
}
.user-input::-webkit-input-placeholder,
.pwd-input::-webkit-input-placeholder {
  color: var(--tertiary);
  opacity: 0.9;
}
.user-input::-moz-placeholder,
.pwd-input::-moz-placeholder {
  color: var(--tertiary);
  opacity: 0.9;
}
.user-input:-ms-input-placeholder,
.pwd-input:-ms-input-placeholder {
  color: var(--tertiary);
  opacity: 0.9;
}
.user-input::-ms-input-placeholder,
.pwd-input::-ms-input-placeholder {
  color: var(--tertiary);
  opacity: 0.9;
}
.user-input::placeholder,
.pwd-input::placeholder {
  color: var(--tertiary);
  opacity: 0.9;
}

/* background-image */
.user-input {
  background-image: url('./ico-usr-blanco.png');
}

.pwd-input {
  background-image: url('./ico-pwd-blanco.png');
}

.dark {
  width: 100%;
  background-color: var(--primary);
}

.one-column-form {
  flex-flow: column;
}

.sub-title {
  font-size: 2rem;
}

.paragraph {
  font-size: 1rem;
}

.super-big-text {
  font-size: 3rem;
  font-family: 'Recoleta SemiBold';
}

.logo {
  width: 60%;
}

@media (min-width: 500px) {
  /* desktop version */

  .logo {
    width: 30%;
  }
}
