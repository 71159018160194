label {
  color: var(--primary);
  font-family: 'Roboto-Bold';
}

textarea,
input,
select {
  width: 270px;
  height: 25px;

  margin: var(--gap);

  background: transparent;

  color: var(--almost-black);

  border: none;

  font-family: 'Roboto-Regular';
}

input[type='checkbox'],
input[type='radio'] {
  width: auto;
  display: flex;
  width: 25px;
}

.input:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid var(--secondary);
}

/* placeholder */
.input::-webkit-input-placeholder {
  color: var(--primary);
  opacity: 0.9;
}
.input::-moz-placeholder {
  color: var(--primary);
  opacity: 0.9;
}
.input:-ms-input-placeholder {
  color: var(--primary);
  opacity: 0.9;
}
.input::-ms-input-placeholder {
  color: var(--primary);
  opacity: 0.9;
}
.input::placeholder {
  color: var(--primary);
  opacity: 0.9;
}

/* border */
.input {
  border-bottom: 2px solid var(--primary);
}

.dark-back-input {
  border-bottom: 2px solid var(--tertiary);
}

button,
.mini-button {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 170px;
  height: 6vh;

  margin: var(--gap);

  background-color: var(--primary);
  border: 1px solid var(--secondary);
  border-radius: var(--radius);

  font-family: 'Roboto-Bold';
  color: var(--tertiary);
}

button:hover,
.mini-button:hover {
  color: (--tertiary);
  background-color: var(--secondary);
}

.mini-button {
  min-width: 50px;
}

.alternate-button {
  background-color: var(--light-text);
  border: 2px solid var(--primary);

  color: var(--primary);
}

.light-button,
.main-dark-button {
  min-width: 350px;
  height: 8vh;

  background-color: transparent;
  border: 2px solid var(--tertiary);

  font-family: 'Roboto-Bold';
  font-size: 1.5rem;
  color: var(--tertiary);
}

.main-dark-button {
  background-color: var(--secondary);
  border: 2px solid var(--secondary);
}

.main-dark-button:hover {
  background-color: transparent;
  border: 2px solid var(--tertiary);
}

table,
.sub-table {
  width: -webkit-fill-available;
  border-collapse: separate;
  border-spacing: var(--gap);
}

.sub-table {
  background-color: var(--pure-white);
}

thead {
  text-align: justify;
}

thead > tr > th {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;

  padding: var(--gap);

  border-radius: var(--radius);

  font-family: 'Roboto-Bold';

  text-align: justify;
}

tbody > tr > td {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px;

  padding: var(--gap);

  border-radius: var(--radius);

  text-align: justify;
}

tbody > tr:nth-child(even) > td {
  background-color: var(--primary);
  color: var(--tertiary);
}

tbody > tr:nth-child(even) > td > button {
  background-color: transparent;
  color: var(--tertiary);
  border-color: var(--tertiary);
}

tbody > tr:nth-child(odd) > td {
  background-color: var(--secondary);
  color: var(--tertiary);
}

tbody > tr:nth-child(odd) > td > button {
  background-color: transparent;
  color: var(--tertiary);
  border-color: var(--tertiary);
}

fieldset {
  max-width: 95%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;

  min-inline-size: 0;

  margin: 0;

  padding: var(--gap);

  display: grid;
  align-items: center;
  place-items: center;
  grid-gap: var(--gap);

  border: solid 2px var(--secondary);
  border-radius: var(--radius);
}

legend {
  font-family: 'Roboto-Bold';
}

.link {
  margin: var(--gap);

  font-family: 'Roboto-Regular';
  text-decoration: underline;

  background-color: transparent;
  border: none;
  border-radius: 0;

  color: var(--almost-black);

  cursor: pointer;
}

.link:hover {
  background-color: transparent;
}

@media (min-width: 500px) {
  /* desktop version */

  table,
  .sub-table {
    width: -webkit-fill-available;
  }

  fieldset {
    max-width: 50%;    
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}
