/* general mobile version */

* {
  --box-width: 200px;
}

.light-text {
  color: var(--pure-white);
}

.gray {
  color: var(--tertiary);
}

.field {
  width: 290px;
  padding: var(--gap);
  display: grid;
  place-items: center;
}

.boxes,
.forms {
  width: 99%;

  padding: var(--gap);

  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.box {
  width: var(--box-width);
  height: 140px;

  border-radius: var(--radius);

  display: grid;
  place-items: center;
  grid-gap: var(--gap);

  text-align: center;
}

/* multi-part */
.multi-part {
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.part {
  display: grid;
  grid-gap: var(--gap);
}

.big-part {
  width: 100vw;

  display: flex;
  place-content: center;
  place-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.title {
  margin: var(--gap);

  justify-self: center;
  text-align: center;
}

.title-font {
  font-family: 'Recoleta SemiBold';
}

.big-text {
  font-size: 1.2rem;
}

.buttons {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  place-items: center;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.module {
  display: flex;
  padding: var(--gap);
}

.module > img {
  width: 30px;
  height: 30px;
}

.module > label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  padding: var(--gap);
}

.sub-forms {
  width: 100%;

  padding: var(--gap);

  display: flex;
  flex-direction: column;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  place-items: center;
}

.sub-buttons {
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  place-items: center;
}

@media (min-width: 500px) {
  /* desktop version */

  .logo {
    width: 30%;
  }

  .buttons {
    justify-content: space-evenly;
  }
}
