.menu-om {
  display: flex;
  justify-self: flex-end;
}

.menu-od {
  display: none;
}

.menu {
  width: 55vw;
  height: auto;

  padding-top: 1vh;
  padding-bottom: 1vh;

  background-color: var(--primary);

  z-index: 2;

  display: grid;
  place-items: center;
}

.menu-options {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;  
}

.menu-option {
  display: flex;
  align-items: center;

  width: auto;
  height: 50px;

  padding: var(--gap);
}

.menu-option:hover {
  border-left: solid 1px var(--secondary);
  border-right: solid 1px var(--secondary);
}

.menu-option-image {
  width: 30px;
}

.menu-option-image > img {
  width: 100%;
}

.menu-option-text {
  padding-left: 4vw;
  padding-right: 0;
}

.menu-button {
  width: 15vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.menu-button > img {
  width: 35px;
}

@media (min-width: 500px) {
  /* desktop version */

  * {
    --menu-width: 84vw;
  }

  .menu-om {
    display: none;
  }

  .menu-od {
    width: var(--menu-width);

    display: grid;
    place-items: center;
  }

  .menu {
    width: var(--menu-width);
    height: auto;
  }

  .menu-options {
    width: var(--menu-width);
    height: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .menu-option {
    width: auto;
    padding-left: var(--gap);
    padding-right: var(--gap);
  }

  .menu-option-image {
    width: 20px;
  }

  .menu-option-image > img {
    width: 100%;
  }

  .menu-option-text {
    padding-left: 0.5vw;
    padding-right: 0.5vw;
  }

  .menu-option-text > label {
    font-size: 1.02rem;
  }
}
