.home-button {
  width: 50vw;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.home-button > img {
  width: 160px;
}

@media (min-width: 500px) {
  /* desktop version */

  .home-button {
    width: 14vw;
  }
}
