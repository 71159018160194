/* font recoleta */
@font-face {
  font-family: 'Recoleta SemiBold';

  src: url('./fonts/Recoleta-SemiBold.otf');
}

@font-face {
  font-family: 'Recoleta Medium';
  src: url('./fonts/Recoleta-Medium.otf');
}

/* font roboto */
@font-face {
  font-family: 'Roboto-Black';
  src: url('./fonts/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('./fonts/Roboto-ThinItalic.ttf');
}

* {
  /* colors */
  --primary: #005356;
  --secondary: #f39325;
  --tertiary: #fff5e9;
  --pure-white: #ffffff;
  --almost-black: #101f14;

  /* variables */
  --gap: 5px;
  --radius: 5px;

  /* general font */
  font-family: 'Roboto-Regular';
  color: var(--primary);
  font-size: 1rem;
  font-style: normal;
  text-decoration: none;
  font-weight: normal;

  /* default reset */
  cursor: auto;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  display: flexbox;

  /* overflow */
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

html,
body {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}

hr {
  width: 90%;
  border: solid 1px var(--almost-black);
}

img {
  max-width: 100%;
  display: block;
}

.body {
  width: 100vw;
  height: auto;

  display: grid;
  place-items: center;

  grid-template-columns: auto;
  grid-template-rows: auto auto auto;

  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-gap: var(--gap);

  z-index: 5;
}

header {
  width: 100%;
  min-height: 6vh;

  padding: var(--gap);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  background-color: var(--primary);
  color: var(--tertiary);
}

main {
  width: 100%;
  height: auto;

  padding: var(--gap);

  display: grid;
  place-items: center;
  grid-gap: var(--gap);

  overflow: auto;
}

footer {
  width: 100%;
  height: 6vh;
  
  display: grid;
  place-items: center;
  grid-gap: var(--gap);

  font-family: 'Recoleta SemiBold';
}

*::-webkit-scrollbar {
  width: 0 !important;
}

.om {
  display: flex;
}

.od {
  display: none;
}

@media (min-width: 500px) {
  /* desktop version */

  * {
    font-family: 'Roboto-Bold';
  }

  main {
    place-items: start;
  }

  .om {
    display: none;
  }

  .od {
    display: table-cell /*revert*/;
    width: 90%;
  }
}
